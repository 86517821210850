import { useMediaQuery } from 'react-responsive'
import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid2'
import { useWindowDimensions } from '../hooks/useFetch'
import { useParams } from 'react-router-dom'

export default function AboutMyron() {
  const { who: bucketName } = useParams()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const { height } = useWindowDimensions()
  const [itemData, setItemData] = useState(null)

  useEffect(() => {
    async function getHomePageImage() {
      const response = await fetch(
        `/api/fetch_image_by_name?bucketName=about_${bucketName}`
      )
      const data = await response.json()
      setItemData(data.response)
    }

    getHomePageImage() // Call the function to fetch the image
  }, [bucketName])

  if (!itemData)
    return (
      <div>
        <CircularProgress
          style={{ position: 'absolute', top: '50%', left: '50%' }}
          color='success'
        />
      </div>
    )

  return (
    <div style={{ backgroundColor: `rgb(5 7 10)`, height }}>
      <div style={{ height: isMobile ? 102 : 80 }} />
      {isMobile && (
        <div>
          <MobileAbout itemData={itemData} />
        </div>
      )}
      {isDesktop && (
        <div>
          <DesktopAbout itemData={itemData} />
        </div>
      )}
    </div>
  )
}

function MobileAbout({ itemData }) {
  return (
    <div style={{ marginTop: 10, margin: '0px 5px' }}>
      <h1 style={{ color: 'white' }}>Myron King</h1>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={6}>
            <div>
              <Paper
                style={{
                  borderRadius: 8,
                  overflow: 'hidden',
                  backgroundColor: 'transparent',
                  color: 'white',
                  border: `0.75px solid gray`,
                  paddingBottom: 3,
                }}
              >
                <img
                  alt={'graduation'}
                  width={'100%'}
                  src={itemData.find((i) => i.name === 'graduation.jpeg').url}
                />
                <div style={{ marginLeft: 3 }}>1</div>
                <div style={{ marginLeft: 3 }}>2</div>
              </Paper>
            </div>
          </Grid>
          <Grid size={6}>
            <Paper>
              This page is currently under construction. Please pardon my dust.
            </Paper>
          </Grid>
          <Grid size={4}>
            <Paper>size=4</Paper>
          </Grid>
          <Grid size={8}>
            <Paper>size=8</Paper>
          </Grid>
          <Grid size={4}>
            <Paper>size=4</Paper>
          </Grid>
          <Grid size={4}>
            <Paper>size=4</Paper>
          </Grid>
          <Grid size={4}>
            <Paper>size=4</Paper>
          </Grid>
          <Grid size={6}>
            <Paper>size=6</Paper>
          </Grid>
          <Grid size={6}>
            <Paper>size=6</Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

function DesktopAbout({ itemData }) {
  return (
    <div style={{ marginTop: 10 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={8}>
            <Paper>size=8</Paper>
          </Grid>
          <Grid size={4}>
            <Paper>size=4</Paper>
          </Grid>
          <Grid size={4}>
            <Paper>size=4</Paper>
          </Grid>
          <Grid size={8}>
            <Paper>size=8</Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
