import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useMediaQuery } from 'react-responsive'

const initialForm = {
  firstName: '',
  lastName: '',
  email: '',
}

export function FormDialog({ open, handleClose }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [user, setUser] = useState(initialForm)

  useEffect(() => {
    if (!open) setUser(initialForm)
  }, [open])

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: isMobile ? '90%' : '30%',
            },
          },
        }}
      >
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to my website, and get all the cool updates I post
            here, please enter your name and email address. I will send updates
            occasionally.
          </DialogContentText>
          <TextField
            value={user.firstName}
            autoFocus={true}
            required
            margin='dense'
            id='first'
            name='first'
            label='First Name'
            type='first'
            fullWidth
            variant='standard'
            onChange={(event) =>
              setUser({ ...user, firstName: event.target.value })
            }
          />
          <TextField
            value={user.lastName}
            autoFocus={false}
            required
            margin='dense'
            id='last'
            name='last'
            label='Last Name'
            type='last'
            fullWidth
            variant='standard'
            onChange={(event) =>
              setUser({ ...user, lastName: event.target.value })
            }
          />
          <TextField
            value={user.email}
            autoFocus={false}
            required
            margin='dense'
            id='email'
            name='email'
            label='Email Address'
            type='email'
            fullWidth
            variant='standard'
            onChange={(event) =>
              setUser({ ...user, email: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(null)}>Cancel</Button>
          <Button
            disabled={
              !(
                user.firstName.length ||
                user.lastName.length ||
                user.email.length
              )
            }
            onClick={() => handleClose(user)}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export function BasicFormDialog({ open, handleClose, content }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: isMobile ? '80%' : '20%',
            },
          },
        }}
      >
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
