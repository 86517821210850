import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function MyImage({ alt, height, src, width, caption, onClick }) {
  return (
    <div
      style={{
        border: '2px solid white',
        borderRadius: 3,
        width: width - 5,
        paddingTop: 5,
        paddingLeft: 5,
      }}
      onClick={onClick}
    >
      <LazyLoadImage
        alt={alt}
        height={height}
        src={src} // use normal <img> attributes as props
        width={width - 10}
      />
      <span>{caption}</span>
    </div>
  )
}

export default MyImage
