import './App.css'
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ReactResponsiveComponent from './pages/ReactResponsiveComponent'
import MobileTravelPage1 from './pages/Mobile/MobileTravelPage1'
import AboutMyron from './pages/AboutMyron'
import ResponsiveAppBar from './components/AppBar'

function App() {
  useEffect(() => {
    async function getHomePageImage() {
      fetch(`/api/fetch_all_images`)
    }

    getHomePageImage() // Call the function to fetch the image
  }, [])

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={wrapperElement(<ReactResponsiveComponent />)}
        />
        <Route
          path='/travel/:path'
          element={wrapperElement(<MobileTravelPage1 />)}
        />
        <Route path='/about/:who' element={wrapperElement(<AboutMyron />)} />
      </Routes>
    </Router>
  )
}

function wrapperElement(element) {
  return (
    <div>
      <ResponsiveAppBar />
      <div className='scrollbars'>{element}</div>
    </div>
  )
}

export default App
