import React, { useEffect, useState } from 'react'
import MasonryImageList from './MasonryImageList'
import { useWindowDimensions } from '../../hooks/useFetch'
import { blogs } from '../../blogs/blogs'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { events } from '../../events/links'
import {
  differenceInMinutes,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
} from 'date-fns'

function calculateTimeLeft(date) {
  const currentDate = new Date()
  const startDate = new Date(date)

  const timeLeft = {
    days: Math.floor(differenceInDays(startDate, currentDate)),
    hours: Math.floor(differenceInHours(startDate, currentDate) % 24),
    minutes: Math.floor(differenceInMinutes(startDate, currentDate) % 60),
    seconds: Math.floor(differenceInSeconds(startDate, currentDate) % 60),
  }

  return timeLeft
}

export default function MobileTravelPage1() {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { path: bucketName } = useParams()
  const [headerImage, setHeaderImage] = useState(null)
  const { width } = useWindowDimensions()
  const blogData = blogs[bucketName]
  const [localEvent, setLocalEvent] = useState(null)
  const [timeLeft, setTimeLeft] = useState()

  useEffect(() => {
    if (!bucketName) return
    setLocalEvent(
      events
        .find((event) => event.listItemName === 'Travel')
        .list.find((trip) => trip.bucketName === bucketName)
    )
  }, [bucketName])

  useEffect(() => {
    if (!localEvent?.dates) return
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(localEvent.dates.startDate))
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <div style={{ backgroundColor: '#518a93' }}>
      {headerImage && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: isMobile ? 102 : 80,
          }}
        >
          <img
            alt={'header_image'}
            width={isMobile ? width : 'auto'}
            height={isMobile ? 'auto' : 400}
            src={headerImage.url}
          />
        </div>
      )}
      {blogData && (
        <div
          style={{
            marginLeft: '3%',
            marginRight: '3%',
            marginTop: headerImage ? 0 : isMobile ? 102 : 80,
          }}
        >
          <h1
            style={{
              color: '#ffffff',
            }}
          >
            {blogData.header1 ?? null}
            {localEvent?.dates && timeLeft && (
              <div
                style={{
                  fontFamily: 'Alaska',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginTop: 30,
                  fontSize: isMobile ? 30 : 40,
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      width: isMobile ? width * 0.2 : 120,
                      textAlign: 'center',
                    }}
                  >
                    {timeLeft.days}
                  </div>
                  <div style={{ width: 5, textAlign: 'center' }}>:</div>
                  <div
                    style={{
                      width: isMobile ? width * 0.2 : 120,
                      textAlign: 'center',
                    }}
                  >
                    {timeLeft.hours < 10
                      ? `0${timeLeft.hours}`
                      : timeLeft.hours}
                  </div>
                  <div style={{ width: 5, textAlign: 'center' }}>:</div>
                  <div
                    style={{
                      width: isMobile ? width * 0.2 : 120,
                      textAlign: 'center',
                    }}
                  >
                    {timeLeft.minutes < 10
                      ? `0${timeLeft.minutes}`
                      : timeLeft.minutes}
                  </div>
                  <div style={{ width: 5, textAlign: 'center' }}>:</div>
                  <div
                    style={{
                      width: isMobile ? width * 0.2 : 120,
                      textAlign: 'center',
                    }}
                  >
                    {timeLeft.seconds < 10
                      ? `0${timeLeft.seconds}`
                      : timeLeft.seconds}
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      width: isMobile ? width * 0.2 : 120,
                      textAlign: 'center',
                    }}
                  >
                    Days
                  </div>
                  <div style={{ width: 5, textAlign: 'center' }}></div>
                  <div
                    style={{
                      width: isMobile ? width * 0.2 : 120,
                      textAlign: 'center',
                    }}
                  >
                    Hours
                  </div>
                  <div style={{ width: 5, textAlign: 'center' }}></div>
                  <div
                    style={{
                      width: isMobile ? width * 0.2 : 120,
                      textAlign: 'center',
                    }}
                  >
                    {isMobile ? 'Mins' : 'Minutes'}
                  </div>
                  <div style={{ width: 5, textAlign: 'center' }}></div>
                  <div
                    style={{
                      width: isMobile ? width * 0.2 : 120,
                      textAlign: 'center',
                    }}
                  >
                    {isMobile ? 'Secs' : 'Seconds'}
                  </div>
                </div>
              </div>
            )}
            {localEvent?.dates && !timeLeft && (
              <SkeletonTimer width={width} isMobile={isMobile} />
            )}
            {blogData.header2 && (
              <div
                style={{ fontSize: isMobile ? 18 : 30, textAlign: 'center' }}
              >
                {blogData.header2}
              </div>
            )}
            {blogData.header3 && (
              <div
                style={{ fontSize: isMobile ? 18 : 30, textAlign: 'center' }}
              >
                {blogData.header3}
              </div>
            )}
          </h1>
          {blogData.body1}
          {blogData.body2}
        </div>
      )}
      <MasonryImageList
        bucketName={bucketName}
        setHeaderImage={setHeaderImage}
      />
    </div>
  )
}

function SkeletonTimer({ width, isMobile }) {
  return (
    <div
      style={{
        fontFamily: 'Alaska',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 30,
        fontSize: isMobile ? 30 : 40,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: 495, height: 60, textAlign: 'center' }}>
          Calculating...
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            width: isMobile ? width * 0.2 : 120,
            textAlign: 'center',
          }}
        >
          Days
        </div>
        <div style={{ width: 5, textAlign: 'center' }}></div>
        <div
          style={{
            width: isMobile ? width * 0.2 : 120,
            textAlign: 'center',
          }}
        >
          Hours
        </div>
        <div style={{ width: 5, textAlign: 'center' }}></div>
        <div
          style={{
            width: isMobile ? width * 0.2 : 120,
            textAlign: 'center',
          }}
        >
          {isMobile ? 'Mins' : 'Minutes'}
        </div>
        <div style={{ width: 5, textAlign: 'center' }}></div>
        <div
          style={{
            width: isMobile ? width * 0.2 : 120,
            textAlign: 'center',
          }}
        >
          {isMobile ? 'Secs' : 'Seconds'}
        </div>
      </div>
    </div>
  )
}
