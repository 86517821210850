import React, { useState, useRef } from 'react'
import { useWindowDimensions } from '../../hooks/useFetch'
import { Footer } from '../../components/Footer'
import Link from '@mui/material/Link'
import {
  CardContent,
  CardMedia,
  Divider,
  Modal,
  Paper,
  Typography,
} from '@mui/material'
import ReactPlayer from 'react-player/youtube'
import { useMediaQuery } from 'react-responsive'

export function LandingPageMobilePortrait() {
  const [showLogoModal, setShowLogoModal] = useState(false)
  const { width } = useWindowDimensions()
  const playerRef = useRef(null)
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })

  return (
    <div>
      <Modal
        style={{
          position: 'fixed',
          marginTop: '8%',
          marginLeft: '5%',
          width: 'fit-content',
          height: 'fit-content',
        }}
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={showLogoModal}
        onClose={() => setShowLogoModal(false)}
        onClick={() => setShowLogoModal(false)}
        closeAfterTransition
      >
        <img
          alt={'modal_image'}
          width={width * 0.9}
          src={'/logo.png'}
          style={{ outline: 'none' }}
        />
      </Modal>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '102px',
          width,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <img alt={'landing_page_image'} width={width} src='/costa_maya.jpg' />
          <img
            alt={'logo_image'}
            align='right'
            style={{ position: 'absolute' }}
            width={width * 0.35}
            src='/logo.png'
            onClick={() => setShowLogoModal(true)}
          />
        </div>
      </div>
      <div style={{ textAlign: '-webkit-center', marginBottom: '2%' }}>
        <Paper elevation={24} style={{ width: width * 0.95 }}>
          <Divider style={{ margin: '5px 0px' }} />
          <div>
            <ReactPlayer
              ref={playerRef}
              controls={isTablet}
              width={width * 0.95}
              // height={220}
              playing={true}
              url='https://youtu.be/-yT-ixql-XI?si=LhrM-uldB2wFpHQI'
            />
          </div>
          <CardContent>
            <Typography
              style={{ textAlign: 'center' }}
              gutterBottom
              variant='h5'
              component='div'
            >
              <span style={{ margin: 0 }}>Hiking 9-29-2024</span>
            </Typography>
            <Typography
              style={{ textAlign: 'center' }}
              variant='body2'
              sx={{ color: 'text.secondary' }}
            >
              <span style={{ margin: 0 }}>
                This past weekend, my family and I embarked on an exciting
                hiking expedition just 30 minutes north of our home. Our initial
                destination was the captivating Holley Falls, renowned for its
                stunning cascading waters. However, upon arrival, we were met
                with warning signs about active hunting in the area, advising
                hikers to wear bright orange for safety. Prioritizing our
                well-being, and not having any anything bright to wear, we
                decided to pass on the falls and explore an alternative—enter
                the "Edge of the World" in Dawsonville, GA.
              </span>
            </Typography>
          </CardContent>
        </Paper>
        <Paper elevation={24} style={{ width: width * 0.95 }}>
          <Divider style={{ margin: '5px 0px' }} />
          <ReactPlayer
            controls={isTablet}
            width={width * 0.95}
            // height={220}
            url='https://youtu.be/kpU7rsNaFJw'
          />
          <CardContent>
            <Typography
              style={{ textAlign: 'center' }}
              gutterBottom
              variant='h5'
              component='div'
            >
              <span style={{ margin: 0 }}>Tatum</span>
            </Typography>
            <Typography
              style={{ textAlign: 'center' }}
              variant='body2'
              sx={{ color: 'text.secondary' }}
            >
              <span style={{ margin: 0 }}>
                Tatum has been focusing on dancing and horseback riding while
                not in school. She really loves riding Skip at&nbsp;
                <Link
                  rel='noreferrer'
                  target='_blank'
                  href='https://bearfootranch.org/'
                >
                  Bearfoot Ranch
                </Link>
                &nbsp;in Cumming.
              </span>
            </Typography>
          </CardContent>
        </Paper>
        <Paper elevation={24} style={{ width: width * 0.95 }}>
          <Divider style={{ margin: '5px 0px' }} />
          <CardMedia
            component={'img'}
            alt={'test'}
            style={{
              height: 220,
              border: '1px solid black',
            }}
            image={'house_halloween.jpeg'}
          />
          <CardContent>
            <Typography
              style={{ textAlign: 'center' }}
              gutterBottom
              variant='h5'
              component='div'
            >
              <span style={{ margin: 0 }}>Halloween</span>
            </Typography>
            <Typography
              style={{ textAlign: 'center' }}
              variant='body2'
              sx={{ color: 'text.secondary' }}
            >
              <span style={{ margin: 0 }}>
                Halloween is just around the corner and we are starting to get
                everything ready for the coming Halloween season. We do plan on
                setting up our garage again this year. Hope to see you there!
              </span>
            </Typography>
          </CardContent>
        </Paper>
      </div>
      <Footer />
    </div>
  )
}
