import * as React from 'react'
import Grid from '@mui/material/Grid2'

export const blogs = {
  costa_rica_2017: {
    header1: (
      <p style={{ fontFamily: 'GloriousChristmas' }}>Costa Rica - 2017</p>
    ),
    header2: '',
    header3: '',
    body1: (
      <p style={{ textAlign: 'justify' }}>
        Spending a week at a beach house in Tango Mar, Puntarenas, Costa Rica,
        was the perfect way for my sisters and I to celebrate our newfound
        freedom after college. <br />
        <br />
        The joy of sharing this experience with my daughter, wife, mother, and
        sisters, alongside their future husbands, made the trip unforgettable—a
        celebration of love, life, and laughter in a slice of paradise.
        Surrounded by the lush beauty of the coastline, we reveled in the
        simplicity of life by the beach, spending sunlit days lounging by our
        private pool or enjoying the beach just steps away. <br />
        <br />
        Mornings were a special ritual as we gathered around the kitchen to cook
        what we fondly dubbed the 'Costa Rica breakfast,' all served through the
        charming pass-through bar to us sitting on deck. The genuine
        friendliness of the locals added to our relaxation, making it easy to
        unwind and embrace the pura vida lifestyle. This trip, with its blend of
        family, food, and stunning scenery, created lasting memories that we
        will always treasure.
      </p>
    ),
    body2: '',
    body3: '',
  },
  aruba_2010: {
    header1: <p style={{ fontFamily: 'GloriousChristmas' }}>Aruba - 2010</p>,
    header2: '',
    header3: '',
    body1: <p style={{ textAlign: 'justify' }}></p>,
    body2: '',
    body3: '',
  },
  wedding_2011: {
    header1: <p style={{ fontFamily: 'GloriousChristmas' }}>Wedding - 2011</p>,
    header2: '',
    header3: '',
    body1: <p style={{ textAlign: 'justify' }}></p>,
    body2: '',
    body3: '',
  },
  indy_500_2010: {
    header1: <p style={{ fontFamily: 'GloriousChristmas' }}>Indy 500 - 2010</p>,
    header2: '',
    header3: '',
    body1: <p style={{ textAlign: 'justify' }}></p>,
    body2: '',
    body3: '',
  },
  hawaii_2009: {
    header1: <p style={{ fontFamily: 'GloriousChristmas' }}>Hawaii - 2009</p>,
    header2: '',
    header3: '',
    body1: <p style={{ textAlign: 'justify' }}></p>,
    body2: '',
    body3: '',
  },
  california_2019: {
    header1: (
      <p style={{ fontFamily: 'GloriousChristmas' }}>California - 2019</p>
    ),
    header2: '',
    header3: '',
    body1: <p style={{ textAlign: 'justify' }}></p>,
    body2: '',
    body3: '',
  },
  helen_2023: {
    header1: (
      <p style={{ fontFamily: 'GloriousChristmas' }}>Helen, GA - 2023</p>
    ),
    header2: '',
    header3: '',
    body1: (
      <p style={{ textAlign: 'justify' }}>
        Our family getaway to Helen, GA, was a heartwarming adventure filled
        with laughter, exploration, and unforgettable memories. We rented a
        charming house nestled in the hills just outside of town, offering the
        perfect blend of tranquility and proximity to the lively heart of Helen.
        Each evening, as the sun set, we gathered around the dining room table
        for spirited board game sessions (Donner Dinner Party anyone?), where
        the friendly competition (and an adult beverage or two) often led to
        fits of laughter that lingered long into the night.
        <br />
        <br />
        A particularly entertaining highlight of the trip was our visit to the
        inflatable fun zone the Helen Fun Factory, where Tatum and Zekey had a
        blast bouncing around on the giant inflatables and shootin’ some
        baskets. It was pure joy to watch them leap and squeal with delight,
        their faces lighting up with each jump and slide. Although the
        inflatable attraction had some quirky decor that gave it a slightly
        eerie vibe, the kids were in their element, diving into games and
        enjoying their time, while the adults shared amused glances and chuckles
        from the sidelines.
        <br />
        <br />
        Shopping in downtown Helen was another delightful experience. The
        colorful charm of the town, with its Bavarian-style architecture and
        unique shops, provided the perfect backdrop for finding souvenirs and
        indulging in some retail therapy. My mom and I enjoyed browsing local
        shops for gnomes of all sorts, while everyone noticed all the IU goodies
        (Hoo Hoo Hoo Hoosiers!).
        <br />
        <br />
        One of the most thrilling moments of our vacation was riding the GA
        mountain coaster. Racing down the scenic track, surrounded by the
        stunning beauty of the Georgia mountains, was a rush of adrenaline that
        had us all cheering as we soared through the curves and dips. The smiles
        and exhilaration were contagious!
        <br />
        <br />
        In the end, while I chose to skip the tattoo session, witnessing my
        everyone’s excitement was great, and now I wish I had partaken in the
        good times. Our time in Helen was a beautiful reminder of the joy of
        spending time together, filled with laughter, adventures, and those
        small moments that make family bonds even stronger. As we packed up to
        leave, we knew we had woven another cherished chapter into our family's
        story, leaving Helen with hearts and minds full of joy and connection. I
        know I am definitely looking forward to a return visit with everyone.
      </p>
    ),
    body2: '',
    body3: '',
  },
  cruise_2023: {
    header1: (
      <p
        style={{
          fontFamily: 'GloriousChristmas',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        Tampa, FL
        <span style={{ fontFamily: 'sans-serif' }}>&nbsp;&gt;&nbsp;</span>
        Cozumel
        <span style={{ fontFamily: 'sans-serif' }}>&nbsp;&gt;&nbsp;</span>
        Roatan
        <span style={{ fontFamily: 'sans-serif' }}>&nbsp;&gt;&nbsp;</span>
        Belize
        <span style={{ fontFamily: 'sans-serif' }}>&nbsp;&gt;&nbsp;</span>
        Costa Maya - April, 2023
      </p>
    ),
    header2: '',
    header3: '',
    body1: <p style={{ textAlign: 'justify' }}></p>,
    body2: '',
    body3: '',
  },
  alaska_2025: {
    header1: (
      <p
        style={{
          fontFamily: 'AlaskaIce',
          fontSize: 200,
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          fontWeight: 100,
          margin: 0,
        }}
      >
        A<span style={{ color: '#27278f' }}>laska</span>
      </p>
    ),
    header2: <p>Adventure Awaits: Our Exciting Family Vacation to Alaska!</p>,
    header3: '',
    body1: (
      <p style={{ textAlign: 'justify' }}>
        Get ready for an unforgettable family adventure as we set sail on a
        scenic cruise exploring the breathtaking Pacific Northwest and stunning
        Alaskan landscapes! Our journey begins in Seattle, WA, before heading to
        Ketchikan’s charming Ward Cove, followed by the majestic sights of
        Juneau and Skagway. We'll experience the awe-inspiring beauty of Icy
        Strait Point and glide through the serene Endicott Arm, arriving at the
        magnificent Dawes Glacier. As our cruise wraps up, we’ll stop in the
        picturesque city of Victoria, BC, before returning to Seattle for
        cherished memories and stories to share!
      </p>
    ),
    body2: (
      <Grid container spacing={2}>
        <Grid size={6}>
          <div
            style={{
              width: '100%',
              margin: 0,
              overflow: 'hidden',
              position: 'relative',
              height: '90%',
            }}
          >
            <img
              alt={'ncl_joy_large'}
              width={'100%'}
              src='/ncl_joy_large.jpg'
            />
          </div>
        </Grid>
        <Grid size={6}>
          <div
            style={{
              width: '100%',
              margin: 0,
              overflow: 'hidden',
              position: 'relative',
              height: '90%',
            }}
          >
            <img
              width={'100%'}
              title='9-Day Alaska Round-Trip Seattle: Dawes Glacier'
              alt='9-Day Alaska Round-Trip Seattle: Dawes Glacier'
              src='/cruise_map.jpg'
            ></img>
          </div>
        </Grid>
        <Grid size={12}>
          <div
            style={{
              width: '100%',
              margin: 0,
              overflow: 'hidden',
              position: 'relative',
              height: '90%',
            }}
          >
            <img
              width={'100%'}
              title='9-Day Alaska Round-Trip Seattle: Dawes Glacier'
              alt='9-Day Alaska Round-Trip Seattle: Dawes Glacier'
              src='/cruise_schedule.jpg'
            ></img>
          </div>
        </Grid>
        {/* <Grid size={8}>
          <div>size=8</div>
        </Grid> */}
      </Grid>
    ),
    body3: '',
  },
}
