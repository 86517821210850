import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { LandingPage } from './Desktop/LandingPage'
import { LandingPageMobilePortrait } from './Mobile/LandingPageMobilePortrait'
// import TestFullScreen from './Mobile/TestFullScreen'

export default function ReactResponsiveComponent() {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  const isDesktop = useMediaQuery({ minWidth: 992 })

  return (
    <div>
      {isDesktop && <LandingPage />}
      {isTablet && <LandingPageMobilePortrait />}
      {isMobile && <LandingPageMobilePortrait />}
      {/* {isTablet && <TestFullScreen />}
      {isMobile && <TestFullScreen />} */}
    </div>
  )
}
