import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Modal } from '@mui/material'
import { useWindowDimensions } from '../hooks/useFetch'
import { useMediaQuery } from 'react-responsive'
import ReactPlayer from 'react-player'

const PAGE_WIDTH = 2519

function Desktop({ children }) {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

function Mobile({ children }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

export default function ImgMediaCard(props) {
  const { image, primaryText, secondaryText, type = 'img' } = props
  const { width, height } = useWindowDimensions()
  const [showMainCardImage, setShowMainCardImage] = useState(false)
  const [pageRatio, setPageRatio] = useState(1)

  useEffect(() => {
    setPageRatio(width / PAGE_WIDTH)
  }, [width, height])

  return (
    <Card sx={{ maxWidth: width * 0.25 }}>
      <Modal
        style={{
          position: 'absolute',
          marginTop: '8%',
          marginLeft: '25%',
          width: 'fit-content',
          height: 'fit-content',
        }}
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={showMainCardImage}
        onClose={() => setShowMainCardImage(false)}
        closeAfterTransition
      >
        <img alt={'modal_image'} width={width * 0.5} src={image} />
      </Modal>
      <Desktop>
        {!image.includes('youtu.be') && (
          <CardMedia
            style={{ cursor: 'pointer', border: '1px solid black' }}
            component={type}
            alt={`${image?.split['.']?.[0]}`}
            height={375 * pageRatio}
            image={image}
            onClick={() => setShowMainCardImage(type === 'img')}
          />
        )}
        {image.includes('youtu.be') && (
          <ReactPlayer
            controls={true}
            width={width * 0.2389}
            height={((width * 0.2389) / 16) * 9}
            url={image}
          />
        )}
      </Desktop>
      <Mobile>
        {' '}
        <CardMedia
          style={{
            cursor: 'pointer',
            border: '1px solid black',
            marginLeft: -9,
          }}
          component={type}
          alt={`${image?.split['.']?.[0]}`}
          height={375 * pageRatio}
          image={image}
          onClick={() => setShowMainCardImage(type === 'img')}
        />
      </Mobile>
      <CardContent style={{ borderTop: '1px solid black', borderRadius: 0 }}>
        <Typography
          gutterBottom
          variant='h5'
          component='div'
          style={{
            fontSize: 20 * pageRatio,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {primaryText}
        </Typography>
        <Typography
          variant='body2'
          sx={{ color: 'text.secondary' }}
          style={{
            fontSize: 16 * pageRatio,
            display: 'flex',
            textAlign: 'center',
          }}
        >
          {secondaryText}
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Button size='small'>Share</Button>
        <Button size='small'>Learn More</Button> */}
      </CardActions>
    </Card>
  )
}
