import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { CircularProgress, Modal } from '@mui/material'
import { useWindowDimensions } from '../../hooks/useFetch'
import { useMediaQuery } from 'react-responsive'
import MyImage from '../utils/MyImage'

export default function MasonryImageList({ bucketName, setHeaderImage }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [modalImageSrc, setModalImageSrc] = useState(false)
  const [showMainCardImage, setShowMainCardImage] = useState(false)
  const { width } = useWindowDimensions()
  const [itemData, setItemData] = useState(null)

  useEffect(() => {
    async function getHomePageImage() {
      const response = await fetch(
        `/api/fetch_image_by_name?bucketName=${bucketName}`
      )
      const data = await response.json()
      setItemData(data.response)
    }

    getHomePageImage() // Call the function to fetch the image
  }, [bucketName]) // Empty dependency array means this effect runs only once on mount

  useEffect(() => {
    setHeaderImage(
      itemData?.find((image) =>
        image?.name?.toLowerCase().includes('header_image')
      )
    )
  }, [itemData, setHeaderImage])

  function handleImageClick(image) {
    setModalImageSrc(image.url)
    setShowMainCardImage(true)
  }

  if (!itemData) return <CircularProgress color='success' />

  return (
    <div>
      <Box
        sx={{
          width: width,
          overflowY: 'scroll',
        }}
      >
        <Modal
          style={{
            position: 'fixed',
            marginTop: '8%',
            marginLeft: '5%',
            width: 'fit-content',
            height: 'fit-content',
          }}
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={showMainCardImage}
          onClose={() => setShowMainCardImage(false)}
          onClick={() => setShowMainCardImage(false)}
          closeAfterTransition
        >
          <img
            alt={'modal_image'}
            style={{ border: '3px solid white', borderRadius: 5 }}
            width={width * 0.9}
            src={modalImageSrc}
          />
        </Modal>
        <ImageList
          variant='masonry'
          cols={itemData.length < 3 ? itemData.length : 3}
          gap={8}
        >
          {itemData.map((item, index) => {
            return (
              <ImageListItem key={index}>
                {/* <img
                  onClick={isMobile ? () => handleImageClick(item) : null}
                  src={item.url}
                  alt={item.name}
                  loading='lazy'
                /> */}
                <MyImage
                  alt={item.name}
                  src={item.url}
                  width={
                    width / (itemData.length < 3 ? itemData.length : 3) -
                    width * 0.01
                  }
                  onClick={isMobile ? () => handleImageClick(item) : null}
                />
              </ImageListItem>
            )
          })}
        </ImageList>
      </Box>
    </div>
  )
}
