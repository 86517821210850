import React from 'react'
import { useWindowDimensions } from '../../hooks/useFetch'
import { Footer } from '../../components/Footer'
import ImgMediaCard from '../../components/ImgMediaCard'
import Grid from '@mui/material/Grid2'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'

export function LandingPage() {
  const { width } = useWindowDimensions()

  return (
    <div>
      <div
        style={{ position: 'absolute', marginTop: '100px', marginLeft: '60%' }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            style={{ justifyContent: 'flex-end', marginRight: '10%' }}
          >
            <Grid size={8}>
              <ImgMediaCard
                type='iframe'
                image={'https://youtu.be/kpU7rsNaFJw'}
                primaryText={'Tatum'}
                secondaryText={
                  <span>
                    Tatum has been focusing on dancing and horseback riding
                    while not in school. She really loves riding Skip at&nbsp;
                    <Link
                      rel='noreferrer'
                      target='_blank'
                      href='https://bearfootranch.org/'
                    >
                      Bearfoot Ranch
                    </Link>
                    &nbsp;in Cumming.
                  </span>
                }
              />
            </Grid>
            <Grid size={8}>
              <ImgMediaCard
                image={'house_halloween.jpeg'}
                primaryText={'Halloween'}
                secondaryText={`Halloween is just around the corner and we are starting to get
          everything ready for the coming Halloween season. We do plan on
          setting up our garage again this year. Hope to see you there!`}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          width: width,
        }}
      >
        <div style={{ display: 'flex' }}>
          <img alt={'landing_page_image'} width={width} src='/costa_maya.jpg' />
          <img
            alt={'logo_image'}
            style={{ position: 'absolute', marginTop: '40px' }}
            width={width * 0.25}
            src='/logo.png'
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}
