import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function ImageAvatars() {
  const navigate = useNavigate()

  function handleMyronClick() {
    navigate('/about/myron')
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Stack direction='row' spacing={2} style={{ margin: 20 }}>
        <Tooltip title={'About Myron'} onClick={handleMyronClick}>
          <Avatar
            style={{ cursor: 'pointer' }}
            alt='Myron King'
            src='/myron.jpg'
          />
        </Tooltip>
        <Tooltip title={'About Kimberly'} onClick={handleMyronClick}>
          <Avatar
            style={{ cursor: 'pointer' }}
            alt='Kimberly King'
            src='/kim.jpg'
          />
        </Tooltip>{' '}
        <Tooltip title={'About Tatum'} onClick={handleMyronClick}>
          <Avatar
            style={{ cursor: 'pointer' }}
            alt='Tatum King'
            src='/tatum.jpg'
          />
        </Tooltip>
        <Tooltip title={'About Doc Holliday'} onClick={handleMyronClick}>
          <Avatar
            style={{ cursor: 'pointer' }}
            alt='Doc Holliday'
            src='/doc.jpg'
          />
        </Tooltip>
        <Tooltip
          title={'About Pennelope von Schweetz'}
          onClick={handleMyronClick}
        >
          <Avatar
            style={{ cursor: 'pointer' }}
            alt='Pennelope von Schweetz'
            src='/penny.jpg'
          />
        </Tooltip>
      </Stack>
    </div>
  )
}
