import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import ImageAvatars from './Header'
import { useWindowDimensions } from '../hooks/useFetch'
import { Avatar } from '@mui/material'
import MenuListComposition from './MenuListComposition'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { events as pages } from '../events/links'

function ResponsiveAppBar() {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  return (
    <AppBar position={'fixed'} style={{ width, zIndex: 1000 }}>
      <Container
        style={{
          width,
          maxWidth: width,
          padding: 0,
          height: isMobile ? '102px' : '80px',
        }}
      >
        <Toolbar
          style={{
            width: width,
            maxWidth: width,
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: isMobile ? 0 : 10,
          }}
          disableGutters
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: width,
              maxWidth: width,
              height: isMobile || isTablet ? 55 : 'auto',
              // marginRight: isMobile || hasScrollBars ? 0 : 30,
            }}
          >
            <Avatar
              style={{ marginLeft: 15, cursor: 'pointer' }}
              alt='crown'
              src='/crown6.png'
              onClick={() => navigate('/')}
            />
            <Box
              sx={{
                flexGrow: 1,
                zIndex: 2000,
                display: { xs: 'none', md: 'flex' },
                marginLeft: 3,
              }}
            >
              {pages.map((page, index) => {
                return (
                  <MenuListComposition
                    key={index}
                    listItemName={page.listItemName}
                    list={page.list}
                  />
                )
              })}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <ImageAvatars />
            </Box>
          </div>
          {isMobile && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'start',
                marginLeft: 5,
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              {pages.map((page, index) => {
                return (
                  <MenuListComposition
                    key={index}
                    listItemName={page.listItemName}
                    list={page.list}
                    icon={page.icon}
                  />
                )
              })}
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
