import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import EventIcon from '@mui/icons-material/Event'
import NewspaperIcon from '@mui/icons-material/Newspaper'

export const events = [
  {
    listItemName: 'Travel',
    list: [
      { name: 'Hawaii 2009', bucketName: 'hawaii_2009', dates: null },
      { name: 'Aruba 2010', bucketName: 'aruba_2010', dates: null },
      { name: 'Indy 500 2010', bucketName: 'indy_500_2010', dates: null },
      { name: 'Wedding 2011', bucketName: 'wedding_2011', dates: null },
      { name: 'Costa Rica 2017', bucketName: 'costa_rica_2017', dates: null },
      { name: 'California 2019', bucketName: 'california_2019', dates: null },
      { name: 'Helen, GA 2023', bucketName: 'helen_2023', dates: null },
      { name: 'Cruise 2023', bucketName: 'cruise_2023', dates: null },
      {
        name: 'Alaska 2025',
        bucketName: 'alaska_2025',
        dates: { startDate: '6/18/2025', endDate: '6/27/2025' },
      },
      { name: 'Disney 2016 (coming soon)', bucketName: null },
      { name: 'Disney 2018 (coming soon)', bucketName: null },
      { name: 'Disney 2021 (coming soon)', bucketName: null },
      { name: 'Disney 2022 (coming soon)', bucketName: null },
    ],
    icon: <AirplanemodeActiveIcon />,
  },
  {
    listItemName: 'News',
    list: [{ name: 'Coming soon', bucketName: null }],
    icon: <NewspaperIcon />,
  },
  {
    listItemName: 'Events',
    list: [
      {
        name: 'Cumming Country Fair & Festival',
        link: 'https://cummingfair.squarespace.com/cumming-country-fair-festival-3',
      },
    ],
    icon: <EventIcon />,
  },
]
