import { useCallback, useEffect, useState } from 'react'
import { useFetch } from 'react-async'

/**
 * @param resource default url... can be overridden in the run function via the resource parameter. nice to have when url doesn't change (i.e. when there is a param in the url)
 * @returns {{data: unknown, setData: (data: unknown, callback?: () => void) => unknown, run: ((function(*): void)|*), isPending: boolean, error: Error}}
 */
export function useGenericFetch({ resource = '' }) {
  const {
    data,
    isPending,
    error,
    setData,
    run: runUseFetch,
  } = useFetch(resource, {}, { defer: true, json: true })

  useEffect(() => {
    console.log('data', data)
  }, [data])

  const run = useCallback(
    (params) => {
      console.log('params', params)
      const { resource, body, customHeaders } = params ?? {}
      runUseFetch((init) => {
        return {
          ...init,
          method: body ? 'POST' : 'GET',
          headers: {
            'content-type': 'application/json',
            ...customHeaders,
          },
          ...(resource && { resource }), // url
          ...(body && {
            body:
              typeof body === 'object'
                ? JSON.stringify(body)
                : JSON.stringify({ body }),
          }),
        }
      })
    },
    [runUseFetch]
  )

  console.log('data', data)

  return { data, isPending, error, setData, run }
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowDimensions
}
