import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/X'
import GitHubIcon from '@mui/icons-material/GitHub'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { BasicFormDialog, FormDialog } from './FormDialog'

function Copyright() {
  return (
    <Typography variant='body2' sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  )
}

export function Footer() {
  const [showFormDialog, setShowFormDialog] = useState(false)
  const [showSubscribedStatus, setShowSubscribedStatus] = useState(false)
  const [subscribedStatus, setSubscribedStatus] = useState('')

  async function subscribe(user) {
    const response = await fetch('/api/subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    })

    const { response: responseJson } = await response.json()

    setShowSubscribedStatus(true)
    setSubscribedStatus(responseJson)
  }

  function handleCloseSubscribeForm(params) {
    if (!params) return setShowFormDialog(false)
    subscribe(params)
  }

  function handleCloseSubscribedStatusDialog() {
    setShowFormDialog(false)
    setShowSubscribedStatus(false)
  }

  return (
    <React.Fragment>
      <FormDialog
        open={showFormDialog}
        handleClose={handleCloseSubscribeForm}
      />
      <BasicFormDialog
        open={showSubscribedStatus}
        handleClose={handleCloseSubscribedStatusDialog}
        content={subscribedStatus}
      />
      <Divider />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 4, sm: 8 },
          py: { xs: 8, sm: 10 },
          textAlign: { sm: 'center', md: 'left' },
          backgroundColor: 'white',
          width: '100%',
          minWidth: '-webkit-fill-available',
          paddingLeft: '3% !important',
          paddingRight: '3% !important',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              minWidth: { xs: '100%', sm: '60%' },
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
              <Typography
                variant='body2'
                gutterBottom
                sx={{ fontWeight: 600, mt: 2 }}
              >
                Join the newsletter
              </Typography>
              <Typography
                variant='body2'
                sx={{ color: 'text.secondary', mb: 2 }}
              >
                Subscribe for weekly updates. No spams ever!
              </Typography>
              <Button
                variant='contained'
                color='primary'
                size='small'
                sx={{ flexShrink: 0 }}
                onClick={() => setShowFormDialog(true)}
              >
                Subscribe
              </Button>
              {/* </Stack> */}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant='body2' sx={{ fontWeight: 'medium' }}>
              Product
            </Typography>
            <Link color='text.secondary' variant='body2' href='#'>
              Features
            </Link>
            <Link color='text.secondary' variant='body2' href='#'>
              Testimonials
            </Link>
            <Link color='text.secondary' variant='body2' href='#'>
              Highlights
            </Link>
            <Link color='text.secondary' variant='body2' href='#'>
              Pricing
            </Link>
            <Link color='text.secondary' variant='body2' href='#'>
              FAQs
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant='body2' sx={{ fontWeight: 'medium' }}>
              Company
            </Typography>
            <Link color='text.secondary' variant='body2' href='#'>
              About us
            </Link>
            <Link color='text.secondary' variant='body2' href='#'>
              Careers
            </Link>
            <Link color='text.secondary' variant='body2' href='#'>
              Press
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant='body2' sx={{ fontWeight: 'medium' }}>
              Legal
            </Typography>
            <Link color='text.secondary' variant='body2' href='#'>
              Terms
            </Link>
            <Link color='text.secondary' variant='body2' href='#'>
              Privacy
            </Link>
            <Link color='text.secondary' variant='body2' href='#'>
              Contact
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 4, sm: 8 },
            width: '100%',
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <div>
            <Link color='text.secondary' variant='body2' href='#'>
              Privacy Policy
            </Link>
            <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
              &nbsp;•&nbsp;
            </Typography>
            <Link color='text.secondary' variant='body2' href='#'>
              Terms of Service
            </Link>
            <Copyright />
          </div>
          <Stack
            direction='row'
            spacing={1}
            useFlexGap
            sx={{ justifyContent: 'left', color: '#117d0d' }}
          >
            <IconButton
              color='inherit'
              size='small'
              href='https://github.com/Caltucky'
              target='_blank'
              rel='noreferrer'
              aria-label='GitHub'
              sx={{ alignSelf: 'center' }}
            >
              <GitHubIcon style={{ width: 30, height: 30 }} />
            </IconButton>
            <IconButton
              color='inherit'
              size='small'
              href='https://x.com/CaltuckyGamer'
              target='_blank'
              rel='noreferrer'
              aria-label='X'
              sx={{ alignSelf: 'center' }}
            >
              <TwitterIcon style={{ width: 30, height: 30 }} />
            </IconButton>
            <IconButton
              color='inherit'
              size='small'
              href='https://www.linkedin.com/in/kingmyron/'
              target='_blank'
              rel='noreferrer'
              aria-label='LinkedIn'
              sx={{ alignSelf: 'center' }}
            >
              <LinkedInIcon style={{ width: 30, height: 30 }} />
            </IconButton>
            <IconButton
              color='inherit'
              size='small'
              href='https://www.facebook.com/MyronWayneKing'
              target='_blank'
              rel='noreferrer'
              aria-label='Facebook'
              sx={{ alignSelf: 'center' }}
            >
              <FacebookIcon style={{ width: 30, height: 30 }} />
            </IconButton>
            <IconButton
              color='inherit'
              size='small'
              href='https://www.youtube.com/@MrCaltucky'
              target='_blank'
              rel='noreferrer'
              aria-label='YouTube'
              sx={{ alignSelf: 'center' }}
            >
              <YouTubeIcon style={{ width: 30, height: 30 }} />
            </IconButton>
            <IconButton
              color='inherit'
              size='small'
              href='https://truthsocial.com/@myronking'
              target='_blank'
              rel='noreferrer'
              aria-label='YouTube'
              sx={{ alignSelf: 'center' }}
            >
              <svg
                width='30px'
                height='30px'
                viewBox='0 0 40 40'
                style={{ alignSelf: 'center', fill: '#117d0d' }}
              >
                <g>
                  <path d='M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M20,38.7C9.7,38.7,1.3,30.3,1.3,20S9.7,1.3,20,1.3S38.7,9.7,38.7,20S30.3,38.7,20,38.7z'></path>
                  <g>
                    <rect x='11.9' y='12.5' width='4.3' height='4.3'></rect>
                    <rect x='23.8' y='23.2' width='4.3' height='4.3'></rect>
                    <polygon points='26,12.5 21.6,12.5 17.3,12.5 17.3,16.7 17.3,16.8 17.3,21 17.3,25.3 17.3,27.5 22.7,27.5 22.7,25.3 22.7,21 22.7,20.9 22.7,16.8 26,16.8 28.1,16.8 28.1,12.5'></polygon>
                  </g>
                </g>
              </svg>
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  )
}
